import { DateTime, Interval } from 'luxon';

const getFallbackReservationWindow = (reservationWindows, spacecraftName, stationName, now) => {
	const fallBackWindowOffset = [
		reservationWindows.spacecrafts?.find(entry => entry.name === spacecraftName)?.window,
		reservationWindows.stations?.find(entry => entry.name === stationName)?.window,
		reservationWindows.default
	].find(Number.isFinite);

	const fallbackWindow = fallBackWindowOffset
		? now.plus({ seconds: fallBackWindowOffset })
		: null;
	return fallbackWindow;
};

const getSystemReservationWindows = (reservationWindows, spacecraftName, stationSystems, now) => {
	const systemsWithOverride = reservationWindows.overrides?.filter(entry =>
		entry.spacecraft === spacecraftName &&
		stationSystems.some(system => system.name === entry.system)
	).map(override => ({
		...override,
		window: now.plus({ seconds: override.window })
	})) || [];

	const systemsNoOverride = reservationWindows.systems
		?.filter(entry =>
			stationSystems.some(system => system.name === entry.name) &&
			!systemsWithOverride.some(override => override.system === entry.name)
		).map(system => ({
			system: system.name,
			window: now.plus({ seconds: system.window })
		})) || [];

	systemsWithOverride.push(...systemsNoOverride);
	return systemsWithOverride;
};

const filterSystemsOnReservationWindows = (intervalEnd, reservationWindows, spacecraftName, stationName, stationSystems) => {
	const now = DateTime.utc();
	const systemBasedWindows = getSystemReservationWindows(reservationWindows, spacecraftName, stationSystems, now);
	const fallbackWindow = getFallbackReservationWindow(reservationWindows, spacecraftName, stationName, now);

	return stationSystems.filter(system => {
		const reservationWindow = systemBasedWindows.find(window => window.system === system.name)
			?.window
			|| fallbackWindow
			|| false;
		return reservationWindow ? intervalEnd <= reservationWindow : true;
	});
};

const generateScheduleForSystems = (selectableSystems, contact, cartContacts, unavailabilities) => {
	return selectableSystems.reduce((schedules, system) => {
		schedules[system.id] = generateScheduleForSystem(
			{ ...contact, system: system },
			cartContacts,
			unavailabilities
		);
		return schedules;
	}, {});
};

const generateScheduleForSystem = (contact, cartContacts, unavailabilities) => {
	const filteredUnavailabilities = unavailabilities.filter(u => u.system_id === contact.system.id)
		.map(unavailability => {
			return {
				...unavailability,
				timeInterval: Interval.fromDateTimes(
					unavailability.timeInterval.start.minus({ seconds: 2 }),
					unavailability.timeInterval.end.plus({ seconds: 2 })
				)
			};
		});

	const cartUnavailabilities = cartContacts
		.filter(c => c?.system?.id === contact.system.id)
		.map(c => contactToUnavailability(c));

	const paddedContact = {
		...contact,
		paddedTimeInterval: getPaddedTimeInterval(contact)
	};

	const allUnavailabilities = filteredUnavailabilities.concat(cartUnavailabilities);
	const conflicts = allUnavailabilities.map(u => u.timeInterval.intersection(paddedContact.paddedTimeInterval)).filter(i => i !== null);
	const alternatives = conflicts.length ? Interval.xor(conflicts.concat([paddedContact.paddedTimeInterval])) : [];
	const alternativesAsContacts = alternatives.map(alternative => {
		const timeInterval = getDepaddedTimeInterval(contact, alternative);

		return {
			...contact,
			from: timeInterval.start,
			to: timeInterval.end,
			paddedTimeInterval: alternative,
			timeInterval: timeInterval
		};
	}).filter(c => c.timeInterval.isValid && c.timeInterval.length('seconds') > 0);

	const schedule = {
		contact: paddedContact,
		unavailabilities: allUnavailabilities,
		conflicts,
		alternatives: alternativesAsContacts,
		longestAlternative: findLongestContact(alternativesAsContacts)
	};

	return {
		...schedule,
		availability: systemState(schedule)
	};
};

const findLongestContact = (contacts) => {
	if (contacts.length === 0) {
		return null;
	}
	return contacts.reduce(function(prev, current) {
		return prev.timeInterval.length() > current.timeInterval.length() ? prev : current;
	});
};

const systemState = (schedule) => {
	if (!schedule) {
		return 'unavailable';
	} else if (schedule.conflicts.length === 0) {
		return 'available';
	} else if (schedule.alternatives.length) {
		return 'conflict';
	}

	return 'occupied';
};

const mostAvailableSchedule = (schedules) => {
	return Object.values(schedules).find(s => s.availability === 'available') ||
		Object.values(schedules).find(s => s.availability === 'conflict');
};

const mostAvailableScheduleStatus = (schedules) => {
	return mostAvailableSchedule(schedules)?.availability || 'occupied';
};

const contactToUnavailability = (contact) => {
	const setupDuration = Math.max(contact.system.setup_duration, contact.missionProfile.setup_duration);
	const teardownDuration = Math.max(contact.system.teardown_duration, contact.missionProfile.teardown_duration);
	return {
		ksat_id: null,
		contact_id: null,
		system: contact.system.name,
		system_id: contact.system.id,
		station: contact.station.name,
		station_id: contact.station.id,
		timeInterval: Interval.fromDateTimes(
			contact.timeInterval.start.minus({ seconds: setupDuration }),
			contact.timeInterval.end.plus({ seconds: teardownDuration })
		),
		start_time: contact.timeInterval.start
			.minus({ seconds: setupDuration })
			.toISO(),
		end_time: contact.timeInterval.end
			.plus({ seconds: teardownDuration })
			.toISO(),
		cause: 'CART'
	};
};

const getDepaddedTimeInterval = (contact, paddedTimeInterval) => {
	const setupDuration = Math.max(contact.system.setup_duration, contact.missionProfile.setup_duration);
	const teardownDuration = Math.max(contact.system.teardown_duration, contact.missionProfile.teardown_duration);

	return Interval.fromDateTimes(
		paddedTimeInterval.start.plus({ seconds: setupDuration }),
		paddedTimeInterval.end.minus({ seconds: teardownDuration })
	);
};

const getPaddedTimeInterval = (contact) => {
	const setupDuration = Math.max(contact.system.setup_duration, contact.missionProfile.setup_duration);
	const teardownDuration = Math.max(contact.system.teardown_duration, contact.missionProfile.teardown_duration);

	return Interval.fromDateTimes(
		contact.timeInterval.start.minus({ seconds: setupDuration }),
		contact.timeInterval.end.plus({ seconds: teardownDuration })
	);
};

const setTimeIntervals = (contact) => {
	contact.timeInterval = Interval.fromDateTimes(contact.from, contact.to);
	contact.paddedTimeInterval = getPaddedTimeInterval(contact);
};

export default {
	generateScheduleForSystems,
	setTimeIntervals,
	systemState,
	mostAvailableSchedule,
	mostAvailableScheduleStatus,
	filterSystemsOnReservationWindows
};
