import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import Operational from './views/Operational.vue';
import Timeline from './views/Timeline.vue';
import Contacts from './views/Contacts.vue';
import Realtime from './views/Realtime.vue';
import RealtimeView from './views/RealtimeView.vue';
import Postpass from './views/Postpass.vue';
import System from './views/System.vue';
import SystemView from './views/SystemView.vue';
import Spacecraft from './views/Spacecraft.vue';
import Admin from './views/Admin.vue';
import Settings from './views/Settings.vue';
import Documentation from './views/Documentation.vue';
import Releaselog from './views/Releaselog.vue';
import NewContact from './views/NewContact.vue';
import EditContact from './views/EditContact.vue';
import Login from './views/Login.vue';
import ResetPassword from './views/ResetPassword';
import NotFound from './views/NotFound';

const redirectTo = url => ({
	template: '',
	created: function() {
		window.location.href = url;
	}
});

const routes = [
	{
		path: '/',
		redirect: {
			name: 'dashboard'
		}
	},
	{
		path: '/dashboard',
		alias: '/dashboard',
		name: 'dashboard',
		meta: {
			title: 'Dashboard'
		},
		component: Dashboard
	},
	{
		path: '/operational',
		name: 'operational',
		meta: {
			title: 'Operational'
		},
		component: Operational
	},
	{
		path: '/timeline',
		name: 'timeline',
		meta: {
			title: 'Timeline'
		},
		component: Timeline
	},
	{
		path: '/contacts',
		name: 'contacts',
		meta: {
			title: 'Contacts'
		},
		component: Contacts
	},
	{
		path: '/realtime',
		name: 'realtime',
		meta: {
			title: 'Real-time'
		},
		component: Realtime
	},
	{
		path: '/realtime/:name',
		name: 'realtime_view',
		props: true,
		meta: {
			title: 'Real-time detailed'
		},
		component: RealtimeView
	},
	{
		path: '/postpass/:id',
		name: 'postpass',
		props: true,
		meta: {
			title: 'Postpass report',
			requiresFeature: 'realtime'
		},
		component: Postpass
	},
	{
		path: '/systems',
		name: 'systems',
		meta: {
			title: 'Systems'
		},
		component: System
	},
	{
		path: '/systems/:name',
		name: 'system_view',
		props: true,
		meta: {
			title: 'System detailed'
		},
		component: SystemView
	},
	{
		path: '/newcontact',
		name: 'new_contact',
		meta: {
			title: 'New contact',
			requiresFeature: 'new_contact'
		},
		component: NewContact
	},
	{
		path: '/editcontact/:id',
		name: 'edit_contact',
		props: true,
		meta: {
			title: 'Edit contact',
			requiresFeature: 'new_contact'
		},
		component: EditContact
	},
	{
		path: '/spacecrafts',
		name: 'spacecrafts',
		meta: {
			title: 'Spacecrafts'
		},
		component: Spacecraft
	},
	{
		path: '/admin',
		name: 'admin',
		meta: {
			title: 'Admin'
		},
		component: Admin
	},
	{
		path: '/settings',
		name: 'settings',
		meta: {
			title: 'Settings'
		},
		component: Settings
	},
	{
		path: '/documentation',
		name: 'documentation',
		meta: {
			title: 'Documentation'
		},
		component: Documentation
	},
	{
		path: '/releaselog',
		name: 'release_log',
		meta: {
			title: 'Release log'
		},
		component: Releaselog
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: 'Login',
			noAuth: true
		},
		component: Login
	},
	{
		path: '/password/reset',
		name: 'password_reset',
		meta: {
			title: 'Password reset',
			noAuth: true
		},
		component: ResetPassword
	},
	{
		path: '/404',
		name: 'not_found',
		meta: {
			title: 'Not found',
			noAuth: true
		},
		component: NotFound
	},
	{
		path: '/:path(.*)*',
		component: redirectTo('/404')
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
