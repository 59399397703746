import axios from 'axios';

const baseURL = '/api/information/';
export default class Information {
	allTenant() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'tenant')
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}

	missionProfiles() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'tenant')
			.then(response => resolve(response.data?.mission_profiles))
			.catch(ex => reject(ex.response))
		);
	}

	reservationWindows() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'tenant')
			.then(response => resolve(response.data?.reservation_windows))
			.catch(ex => reject(ex.response))
		);
	}

	allSystems() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'systems')
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}

	allAntennas() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'systems')
			.then(response => resolve(response.data?.antennas))
			.catch(ex => reject(ex.response))
		);
	}

	antenna(name) {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'systems')
			.then((response) => {
				const antenna = response.data?.antennas.find(antenna => antenna.name === name);
				resolve(antenna);
			})
			.catch(ex => reject(ex.response))
		);
	}

	system(name) {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'systems')
			.then((response) => {
				const system = [...response.data?.antennas ?? [], ...response.data?.telescopes ?? []].find(system => system.name === name);
				resolve(system);
			})
			.catch(ex => reject(ex.response))
		);
	}

	allTelescopes() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'systems')
			.then(response => resolve(response.data?.telescopes))
			.catch(ex => reject(ex.response))
		);
	}

	telescope(name) {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'systems')
			.then((response) => {
				const telescope = response.data?.telescopes.find(telescope => telescope.name === name);
				resolve(telescope);
			})
			.catch(ex => reject(ex.response))
		);
	}

	allStations() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'stations')
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}

	station(name) {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'stations')
			.then((response) => {
				const station = response.data?.find(station => station.name === name);
				resolve(station);
			})
			.catch(ex => reject(ex.response))
		);
	}

	allSpacecrafts() {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'spacecrafts')
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}

	spacecraft(name) {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'spacecrafts')
			.then((response) => {
				const spacecraft = response.data?.find(spacecraft => spacecraft.name === name);
				resolve(spacecraft);
			})
			.catch(ex => reject(ex.response))
		);
	}

	schema(id) {
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'schemas/' + id)
			.then(response => resolve(response.data?.schema))
			.catch(ex => reject(ex.response))
		);
	}

	spacecraftSetup(name) {
		console.log(baseURL + 'spacecraft/' + name);
		return new Promise((resolve, reject) => axios
			.get(baseURL + 'setup/spacecraft/' + name)
			.then(response => resolve(response.data))
			.catch(ex => reject(ex.response))
		);
	}
}
